<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param.sync="param"
          :disabledObj.sync="disabledObj"
          :workResultDatas.sync="workResultDatas"
          :workResultEquips.sync="workResultEquips"
          :workResultInout.sync="workResultInout"
          @getEquips="getEquips"
          @disabledSet="disabledSet"
          @stepBystep2="stepBystep2"
          @closePopup="closePopup"
          @saveWorkResult="saveWorkResult"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'work-order-plan02',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        workResultId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: '',
        firstCopy: false,
        workPlanFlag: false,
        targetEquipments: [],
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      workResultDatas: [],
      workResultEquips: [],
      workResultInout: [],
      splitter: 5,
      tab: 'planInfo',
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      disabledObj: {
        disabled: false,
      },
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '오더내역', component: () => import(`${'./workOrderResultDirection.vue'}`) },
        { key: uid(), name: 'plan', icon: 'engineering', label: '작업계획', component: () => import(`${'./workOrderResultWork.vue'}`) },
        { key: uid(), name: 'team', icon: 'task_alt', label: '자재 및<br/>서비스', component: () => import(`${'./workOrderResultService.vue'}`) },
        // { key: uid(), name: 'worker', icon: 'groups', label: '인력<br/>가용성 확인', component: () => import(`${'./workOrderResultWorker.vue'}`) },
        { key: uid(), name: 'team1', icon: 'task_alt', label: '설비별<br/>고장정보', component: () => import(`${'./workOrderResultBreakdown.vue'}`) },
        { key: uid(), name: 'team2', icon: 'photo', label: '작업결과<br>사진', component: () => import(`${'./workOrderResultFile.vue'}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      if (this.param.workPlanFlag) {
        this.tab = 'plan'
      }
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
    stepBystep2(_param) {
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: {
          stepCd: _param.stepCd,
          woWorkPlanStepCd: _param.woWorkPlanStepCd,
        }
      })
    },
    // _inOut
    saveWorkResult(_works, _equips, ) {
      this.workResultDatas = [];
      this.workResultEquips = [];
      this.workResultInout = [];

      let equips = [];
      let cntequip = 0;
      this.$_.forEach(_equips, _item => {
        equips.push(_item.equipmentCd);
        this.workResultEquips.push({
          name: 'tab' + cntequip,
          label: _item.equipmentNo + '/' + _item.equipmentName,
          param: {workResultId: _item.workResultId, equipmentCd: _item.equipmentCd},
          component: () => import(`${'./workOrderResultBreakdownEquip.vue'}`),
          key: uid(),
        }) 
        cntequip++;
      });
      // let cnt = 0;
      // this.$_.forEach(_works, item => {
      //   this.workResultDatas.push({
      //     name: 'tab' + cnt,
      //     label: item.sortOrder + '.(' + item.workOprTypeName + ') ' + item.workResultWorkName,
      //     param: {workResultId: item.workResultId, workResultWorkId: item.workResultWorkId, equips: equips},
      //     component: () => import(`${'./workOrderResultService.vue'}`),
      //     key: uid(),
      //   })
      //   cnt++;
      // });
      // let cntRel = 0;
      // this.$_.forEach(_inOut, item => {
      //   this.workResultInout.push({
      //     name: 'tab' + cntRel,
      //     label: item.outDt + '/' + item.workOrderName,
      //     param: { workResultId: item.workResultId, materialCd: item.materialCd},
      //     component: () => import(`${"./workOrderResultInout.vue"}`),
      //     key: uid(),
      //   })
      //   cntRel++;
      // });
    },
    disabledSet(_bool) {
      this.disabledObj.disabled = _bool;
    },
    tabClick() {
      this.click = uid();
    },
    getEquips(_data) {
      this.$set(this.param, 'targetEquipments', _data)
    }
  }
};
</script>
